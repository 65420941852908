export const unAuthenticatedRoutes = {
    LOGIN: "/admin/login",
    RESET_PASSWORD: "/admin/reset-password/:resetToken",
    FORGET_PASSWORD: "/admin/forget-password",
    SET_PASSWORD: "/admin/set-password/:token",
    PUBLIC_LOGIN: "/public/login",
    INVITE: "/public/invite"
};

export const authenticatedRoutes = {
    HOME: "/admin/home",
    QUESTIONNAIRE: "/admin/questionnaire",
    APPLICATIONS: "/admin/applications",
    APPLICATION_PROFILE: "/admin/application/:profileId",
    MY_PROFILE: "/admin/my-profile",
    DASHBOARD: "/admin/dashboard",
    QUESTIONS: "/public/questions",
    QUESTION_RESUME: "/public/question/:resumeId",
    SCORE_PROFILE: "/public/score-profile/:profileId",
    DESIGNATION_CENTRAL: "/public/designation-central",
    PRIVACY_POLICY: "/public/privacyPolicy"
};
