import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import AuthService from "../util/auth.service";
import utilService from "../util/utils.service";

const errorLink = onError((operation) => {
    const { networkError, graphQLErrors } = operation;

    if (networkError?.statusCode === 401) {
    }
    if (graphQLErrors && graphQLErrors?.[0]?.message === "Unauthorized") {
        utilService.removeLocalStorageRedirectLogin();
    }
});

const authLink = new ApolloLink((operation, forward) => {
    const userToken = AuthService.getToken();

    const tokenObj = userToken ? userToken : undefined;

    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            ...(tokenObj
                ? {
                      Authorization: tokenObj ? "Bearer " + tokenObj : ""
                  }
                : {})
        }
    }));

    return forward(operation);
});

const httpLink = createHttpLink({
    uri: utilService.graphqlApiUrl
});
const link = from([authLink, errorLink, httpLink]);

const client = new ApolloClient({
    cache: new InMemoryCache({
        dataIdFromObject: (o) => false
    }),
    link: link
});
export default client;
