import { ConfigProvider } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AppLocale from "../languageProvider";
import AuthService from "../util/auth.service";
import { unAuthenticatedRoutes } from "./constant";
import { LOGOUT_KEYS } from "../constants/constant";
import LocalStorageService from "../util/local-storage.service";

const AuthenticatedAppRoutes = React.lazy(() => import("../routes/authenticated-app-routes"));
const UnAuthenticatedAppRoutes = React.lazy(() => import("../routes/unauthenticated-app-routes"));
const UserWebAppRoutes = React.lazy(() => import("./user-web-app-routes"));
const AuthenticatedPublicApp = React.lazy(() => import("./authenticated-public-routes"));
const Routes = () => {
    const { locale, themeType, pathname } = useSelector(({ settings }) => settings);
    let history = useHistory();
    const isTokenExist = AuthService.isTokenExist();
    const isAdminToken = AuthService.isAdminTokenExist();
    const isAdminRoute = pathname.includes("/admin");
    const isInviteRoute = pathname.includes(unAuthenticatedRoutes.INVITE);
    const isPublicToken = AuthService.isPublicTokenExist();

    const currentAppLocale = AppLocale.find((x) => x.id === locale);

    const getRoutesFile = () => {
        if ((isAdminRoute || isAdminToken) && !isPublicToken) {
            if (isTokenExist) {
                return <AuthenticatedAppRoutes />;
            } else {
                return <UnAuthenticatedAppRoutes />;
            }
        } else {
            if (isInviteRoute) {
                for (let key of LOGOUT_KEYS) {
                    LocalStorageService.remove(key);
                }
                return <UserWebAppRoutes />;
            } else if (!isTokenExist) {
                return <UserWebAppRoutes />;
            } else {
                return <AuthenticatedPublicApp />;
            }
        }
    };

    return <ConfigProvider locale={currentAppLocale.lang.antdLocale}>{getRoutesFile()}</ConfigProvider>;
};

export default Routes;
